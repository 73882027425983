exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-glaze-tsx": () => import("./../../../src/pages/app/glaze.tsx" /* webpackChunkName: "component---src-pages-app-glaze-tsx" */),
  "component---src-pages-ceramics-tsx": () => import("./../../../src/pages/ceramics.tsx" /* webpackChunkName: "component---src-pages-ceramics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-film-tsx": () => import("./../../../src/pages/photography/film.tsx" /* webpackChunkName: "component---src-pages-photography-film-tsx" */),
  "component---src-pages-photography-fine-art-tsx": () => import("./../../../src/pages/photography/fine-art.tsx" /* webpackChunkName: "component---src-pages-photography-fine-art-tsx" */),
  "component---src-pages-photography-portrait-tsx": () => import("./../../../src/pages/photography/portrait.tsx" /* webpackChunkName: "component---src-pages-photography-portrait-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */)
}

